import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { IePopupPage } from './modals/ie-popup/ie-popup.page';
import { YearService } from './services/year.service';
import { PwaUpdateService } from './services/pwa-update.service';

interface Document {
  documentMode?: any;
}

declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public brochureData: object;

  public appPages = [
    {
      title: 'Plans',
      url: 'landing',
    },
    {
      title: 'Product overview',
      url: 'product-comparison',
    },
    {
      title: 'Contributions',
      url: 'contributions',
    },
    {
      title: 'Added insured benefits',
      url: 'added-insured-benefits',
    },
    {
      title: 'Per product',
      url: 'added-insured-benefits-detailed',
      margin: true
    },
    {
      title: 'Core benefits',
      url: 'core-benefits',
    },
    {
      title: 'Day-to-day benefits',
      url: 'day-to-day-benefits',
    },
    {
      title: 'Co-payments',
      url: 'deductibles',
    },
    {
      title: 'Healthcare partners',
      url: 'healthcare-partners',
    },
    {
      title: 'Exclusions',
      url_2021: `${environment.apiUrl}/docs/default-source/about-medihelp-2021/exclusions-2021.pdf`,
      url_2022: `${environment.apiUrl}/docs/default-source/about-medihelp-2022/exclusions-2022.pdf`,
      url_2023: `${environment.apiUrl}/docs/default-source/about-medihelp-2022/exclusions-2022.pdf`,
      url_2024: `${environment.apiUrl}/docs/default-source/about-medihelp-2024/exclusions-2024.pdf`,
      url_2025: `${environment.apiUrl}/docs/default-source/about-medihelp-2025/adviser-pwa/exclusions-2025.pdf`,
      external: true
    },
    {
      title: 'Provider search',
      url_2021: `${environment.apiUrl}/connect/provider-search`,
      url_2022: `${environment.apiUrl}/connect/provider-search`,
      url_2023: `${environment.apiUrl}/connect/provider-search`,
      url_2024: `${environment.apiUrl}/connect/provider-search`,
      url_2025: `${environment.apiUrl}/connect/provider-search`,
      external: true
    },
    {
      title: 'Terms',
      url: 'terms',
    },
    {
      title: 'Connect',
      url: 'connect',
    },
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public translate: TranslateService,
    private modalCtrl: ModalController,
    public yearService: YearService,
    private updateService: PwaUpdateService
  ) {
    this.initializeApp();

    if (navigator.userAgent.indexOf('MSIE') !== -1
      || navigator.appVersion.indexOf('Trident/') > -1) {
      this.presentIEModal();
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is("capacitor")) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }
    });
  }

  private async presentIEModal() {
    const modal = await this.modalCtrl.create({
      component: IePopupPage,
      cssClass: 'fullscreen'
    });

    modal.present();
  }

  ngOnInit() {

  }
}
