import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TokenService } from './token.service';
import { Router } from '@angular/router';
import { mergeMap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private tokenService: TokenService,
    ) { }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('authToken');

        if (token) {
            if (req.url.includes(environment.providerSearchApiUrl)) {
                req = req.clone({
                    setHeaders: {
                        'Content-Type': 'application/json',
                        'X-API-Key': '3face864-c3a0-44ea-a3e0-0cb4845a20ee:365Pl7daMbmfY+dGy/nwUcTneHzTfTcWequCfiJNm00=',
                    }
                });
            } else {
                req = req.clone({
                    setHeaders: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                });
            }

        }
        return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
            if (error.status === 401 && error.statusText === 'Unauthorized') {
                localStorage.removeItem('authToken');

                return this.tokenService.getAuthToken().pipe(mergeMap(
                    (data: any) => {
                        if (data) {
                            localStorage.setItem('authToken', data.access_token);
                            req = req.clone({
                                setHeaders: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${data.access_token}`,
                                }
                            });
                            return next.handle(req);
                        }
                        return throwError(error);
                    })
                );
            }
            return throwError(error);
        })
        ) as Observable<HttpEvent<any>>;
    }
}
