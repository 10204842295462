import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private http: HttpClient) { }

  getAuthToken(): Observable<any> {
    const formData = new HttpParams()
      .set('username', 'mh-digitalbrochure-api@warpdevelopment.com')
      .set('password', '2HToH@GsM6*5ud')
      .set('grant_type', 'password')
      .set('scope', 'openid')
      .set('client_id', 'MedihelpPWAApp')
      .set('client_secret', 'secret');

    return this.http.post(`${environment.apiUrl}/Sitefinity/Authenticate/OpenID/connect/token`, formData.toString(), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }
}
