import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Year } from '../models/year';

@Injectable({
  providedIn: 'root'
})
export class YearService {

  public year: string;

  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) { }

  getBrochureYear(): Observable<Year[]> {
    return this.http.get(`${environment.apiUrl}/api/digital-compare-brochures/brochureyears?sf_provider=OpenAccessProvider&$expand=CoverImage,CoverMobileImage&sf_culture=${this.translate.currentLang}`).pipe(map((res: any) => {
      return res.value;
    }));
  }
}
