import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: `2025/landing`,
    pathMatch: 'full'
  },
  {
    path: ':year',
    redirectTo: `:year/landing`,
    pathMatch: 'full'
  },  
  {
    path: ':year/landing',
    loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: ':year/product/:product',
    loadChildren: () => import('./pages/product/product.module').then(m => m.ProductPageModule)
  },
  {
    path: ':year/product-comparison',
    loadChildren: () => import('./pages/product-comparison/product-comparison.module').then(m => m.ProductComparisonPageModule)
  },
  {
    path: ':year/contributions',
    loadChildren: () => import('./pages/contributions/contributions.module').then(m => m.ContributionsPageModule)
  },
  {
    path: ':year/medihelp-medical-aid-in-action',
    loadChildren: () => import('./pages/medihelp-medical-aid-in-action/medihelp-medical-aid-in-action.module').then(m => m.MedihelpMedicalAidInActionPageModule)
  },
  {
    path: ':year/healthcare-partners',
    loadChildren: () => import('./pages/healthcare-partners/healthcare-partners.module').then(m => m.HealthcarePartnersPageModule)
  },
  {
    path: ':year/core-benefits',
    loadChildren: () => import('./pages/core-benefits/core-benefits.module').then(m => m.CoreBenefitsPageModule)
  },
  {
    path: ':year/core-benefits/:benefit',
    loadChildren: () => import('./pages/benefit/benefit.module').then(m => m.BenefitPageModule)
  },
  {
    path: ':year/day-to-day-benefits/:benefit',
    loadChildren: () => import('./pages/benefit/benefit.module').then(m => m.BenefitPageModule)
  },
  {
    path: ':year/day-to-day-benefits',
    loadChildren: () => import('./pages/day-to-day-benefits/day-to-day-benefits.module').then(m => m.DayToDayBenefitsPageModule)
  },
  {
    path: ':year/deductibles',
    loadChildren: () => import('./pages/deductibles/deductibles.module').then(m => m.DeductiblesPageModule)
  },
  {
    path: ':year/added-insured-benefits',
    loadChildren: () => import('./pages/added-insured-benefits/added-insured-benefits.module').then(m => m.AddedInsuredBenefitsPageModule)
  },
  {
    path: ':year/terms',
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsPageModule)
  },
  {
    path: ':year/connect',
    loadChildren: () => import('./pages/connect/connect.module').then(m => m.ConnectPageModule)
  },
  {
    path: ':year/added-insured-benefits-detailed',
    loadChildren: () => import('./pages/added-insured-benefits-detailed/added-insured-benefits-detailed.module').then(m => m.AddedInsuredBenefitsDetailedPageModule)
  },
  {
    path: ':year/provider-search',
    loadChildren: () => import('./pages/provider-search/provider-search.module').then(m => m.ProviderSearchPageModule)
  },
  {
    path: 'ie-popup',
    loadChildren: () => import('./modals/ie-popup/ie-popup.module').then(m => m.IePopupPageModule)
  }
];

@NgModule({
  imports: [

    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
