import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class PwaUpdateService {

  constructor(
    updates: SwUpdate,
    private alert: AlertService
  ) {
    updates.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);

      //new version available
      this.alert.show({
        message: "New version available. Do you want to update now?",
        buttons: [
          'Later',
          {
            text: 'Update now', handler: () => {
              updates.activateUpdate().then(() => document.location.reload());
            }
          },
        ],
      });
    });
  }
}
