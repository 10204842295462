import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ie-popup',
  templateUrl: './ie-popup.page.html',
  styleUrls: ['./ie-popup.page.scss'],
})
export class IePopupPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
