import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertCtrl: AlertController
  ) { }

  async show(options: any) {
    const alert = await this.alertCtrl.create(options);
    return alert.present();
  }
}
